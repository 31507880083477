/** * @module assignment */

import _ from "lodash";
import { toast } from "react-toastify";

import {
  ASSIGNMENT_PERIMETER,
  ASSIGNMENT_TYPES,
  KANBAN_COLUMN_STATES,
} from "utils/constants/assignment/assignment";
import { EXECUTIVE_SEARCH_IDS } from "utils/constants/assignment/executive-search";
import { LEADERSHIP_ADVISORY_IDS } from "utils/constants/assignment/leadership-advisory";
import { INTERIM_MANAGEMENT_IDS } from "utils/constants/assignment/interim-management";
import { DEFAULT_FILTER_ASSIGNMENT_STATUS_OPTION } from "utils/constants/assignment/filter-status";
import {
  IN_THE_SAME_TEAM_REPARTITION_VALUE,
  ORIGINATOR_NOT_IN_THE_SAME_TEAM_REPARTITION_VALUE,
  PRODUCER_AND_ORIGINATOR_SAME_REPARTITION_VALUE,
  PRODUCER_NOT_IN_THE_SAME_TEAM_REPARTITION_VALUE,
} from "utils/constants/invoice/invoice";

import { getSessionStorage } from "./session";
import { actionRules, actionRulesReverse } from "./action";
import { createContentItem } from "./object";
import {
  extractCombinedName,
  formatBooleanToString,
  getValueOrDefault,
} from "./string";
import { extractFullName } from "./user";

const { atsBase } = getSessionStorage();

/**
 * Retrieves the latest assignment in progress from a list of assignments.
 *
 * @param {Object[]} [assignments=[]] - The list of assignment objects.
 * @param {string} assignments[].create_date - The creation date of the assignment.
 * @param {number} assignments[].ats_assignment_id - The ID of the assignment.
 * @returns {Object|undefined} The latest assignment in progress, or undefined if the list is empty.
 * @example
 * // Assuming `assignments` is an array of assignment objects.
 * const assignments = [
 *   { create_date: '2024-06-01T12:00:00Z', ats_assignment_id: 1 },
 *   { create_date: '2024-07-01T12:00:00Z', ats_assignment_id: 2 },
 * ];
 * const latestAssignment = getLatestAssignmentInProgress(assignments);
 * console.log(latestAssignment); // Output: { create_date: '2024-07-01T12:00:00Z', ats_assignment_id: 2 }
 */
export function getLatestAssignmentInProgress(assignments = []) {
  let latestAssignmentInProgress;

  const orderedAssignments = _.orderBy(
    assignments,
    [(item) => new Date(item.create_date), (item) => item.ats_assignment_id],
    ["desc", "desc"],
  );

  if (orderedAssignments.length > 0) {
    const [firstItem] = orderedAssignments;
    latestAssignmentInProgress = firstItem;
  }

  return latestAssignmentInProgress;
}

/**
 * Generates URL parameters from the given scope and query parameters.
 *
 * @param {string} scope - The scope of the URL parameters.
 * @param {Object} [queryParams] - The query parameters for generating URL parameters.
 * @param {number} [queryParams.page=1] - The page number.
 * @param {number} [queryParams.limit=100] - The limit of items per page.
 * @param {string} [queryParams.globalFilter] - A global filter for search.
 * @param {number} [queryParams.user_id] - The user ID for filtering.
 * @param {string} [queryParams.sortDirection] - The direction for sorting.
 * @param {string} [queryParams.sortProperty] - The property to sort by.
 * @param {boolean} [queryParams.offlimit] - The offlimit flag.
 * @param {Array} [queryParams.filter] - An array of filters.
 * @param {string|string[]} [queryParams.state] - The state or states for filtering.
 * @returns {URLSearchParams} The URL parameters.
 * @example
 * const queryParams = {
 *   page: 2,
 *   limit: 50,
 *   globalFilter: 'search term',
 *   user_id: 123,
 *   sortDirection: 'asc',
 *   sortProperty: 'name',
 *   offlimit: true,
 *   filter: [{ key: 'status', value: 'active' }],
 *   state: ['NY', 'CA']
 * };
 * const urlParams = getUrlParams('user', queryParams);
 * console.log(urlParams.toString());
 * // Output: ats_base=&scope=user&page=2&limit=50&search=search%20term&user_id=123&sort[direction]=asc&sort[property]=name&offlimit=true&filter=[{"key":"status","value":"active"}]&state[]=NY&state[]=CA
 */
export function getUrlParams(scope, queryParams) {
  const params = new URLSearchParams();

  params.set("ats_base", atsBase);
  params.set("scope", scope);
  params.set("page", queryParams?.page || 1);
  params.set("limit", queryParams?.limit || 100);

  if (queryParams?.globalFilter) {
    params.set("search", queryParams.globalFilter);
  }
  if (queryParams?.atsCompanyId) {
    params.set("ats_company_id", queryParams.atsCompanyId);
  }

  if (queryParams?.user_id) {
    params.set("user_id", queryParams.user_id);
  }

  if (queryParams?.sortDirection) {
    params.set("sort[direction]", queryParams.sortDirection);
  }

  if (queryParams?.sortProperty) {
    params.set("sort[property]", queryParams.sortProperty);
  }

  if (queryParams?.offlimit) {
    params.set("offlimit", queryParams.offlimit);
  }

  if (queryParams?.filter?.length > 0) {
    params.set("filter", JSON.stringify(queryParams.filter));
  }

  if (queryParams?.state) {
    if (Array.isArray(queryParams.state)) {
      queryParams?.state?.forEach((id) => {
        params.append("state[]", id);
      });
    } else {
      params.append("state", queryParams?.state);
    }
  }

  return params;
}

/**
 * Reorders items between two arrays based on the provided source and destination information.
 *
 * @param {Object} params - The parameters for reordering.
 * @param {Object} params.data - The data object containing arrays to reorder.
 * @param {Object} params.source - The source information for the item to move.
 * @param {string} params.source.droppableId - The ID of the source droppable area.
 * @param {number} params.source.index - The index of the item in the source array.
 * @param {Object} params.destination - The destination information for the item to move.
 * @param {string} params.destination.droppableId - The ID of the destination droppable area.
 * @returns {Object} The updated data object with the item reordered.
 * @example
 * const data = {
 *   list1: ['item1', 'item2', 'item3'],
 *   list2: ['item4', 'item5']
 * };
 * const source = { droppableId: 'list1', index: 1 };
 * const destination = { droppableId: 'list2' };
 * const result = reorderArrayPipe({ data, source, destination });
 * console.log(result);
 * // Output:
 * // {
 * //   list1: ['item1', 'item3'],
 * //   list2: ['item2', 'item4', 'item5']
 * // }
 */
export function reorderArrayPipe({ data, source, destination }) {
  const current = [...data[source.droppableId]];
  const next = [...data[destination.droppableId]];
  const target = current[source.index];

  current.splice(source.index, 1);

  // always drop item on the top (droppedIdIndex,_,_)
  next.splice(0, 0, target);

  return {
    ...data,
    [source.droppableId]: current,
    [destination.droppableId]: next,
  };
}

/**
 * Returns a string representing the drag direction from one point to another.
 *
 * @param {Object} params - The parameters for determining the drag direction.
 * @param {string} params.from - The starting point.
 * @param {string} params.to - The ending point.
 * @returns {string} A string in the format "from -> to" representing the drag direction.
 * @example
 * const direction = dragDirection({ from: 'A', to: 'B' });
 * console.log(direction); // Output: "A -> B"
 */
export function dragDirection({ from, to }) {
  return `${from} -> ${to}`;
}

/**
 * Returns the corresponding kanban column number for a given column ID.
 *
 * @param {string} id - The ID of the kanban column.
 * @returns {number} The corresponding column number. Returns 0 if the ID does not match any known column states.
 * @example
 * const columnNumber = getColumKanbanNum(KANBAN_COLUMN_STATES.IDENTIFIED);
 * console.log(columnNumber); // Output: 1
 */
export function getColumKanbanNum(id) {
  switch (id) {
    case KANBAN_COLUMN_STATES.IDENTIFIED:
      return 1;
    case KANBAN_COLUMN_STATES.QUALIFIED:
      return 2;
    case KANBAN_COLUMN_STATES.LINCOLN_INTERVIEW:
      return 3;
    case KANBAN_COLUMN_STATES.SHORT_LISTED:
      return 4;
    case KANBAN_COLUMN_STATES.CLIENT_INTERVIEW:
      return 5;
    case KANBAN_COLUMN_STATES.IN_OFFER:
      return 6;
    case KANBAN_COLUMN_STATES.RECRUITED:
      return 7;
    default:
      return 0;
  }
}

/**
 * Displays an information toast notification indicating that an operation is in progress.
 *
 * @param {string} [message="Operation In progress"] - The message to display in the toast notification.
 * @returns {void}
 * @example
 * // Display a toast with the default message
 * showToastOnSavingInProgress();
 *
 * // Display a toast with a custom message
 * showToastOnSavingInProgress("Saving data...");
 */
export function showToastOnSavingInProgress(message = "Operation In progress") {
  return toast.info(message, {
    position: "top-right",
    autoClose: 5000,
    progress: undefined,
  });
}

/**
 * Displays an error toast notification with a specified message.
 *
 * @param {string} [message="Oops! We've encountered an error processing your request. Our development team is on it, your concerns are in good hands."] - The message to display in the toast notification.
 * @returns {void}
 * @example
 * // Display a toast with the default message
 * showToastOnError();
 *
 * // Display a toast with a custom message
 * showToastOnError("Custom error message.");
 */
export function showToastOnError(
  message = "Oops! We've encountered an error processing your request. Our development team is on it, your concerns are in good hands.",
) {
  return toast.error(message, {
    position: "top-right",
    autoClose: true,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
}

/**
 * Checks whether a contract can be downloaded based on the provided type ID.
 *
 * @param  {string|number} typeId - The type ID to check.
 * @returns {boolean} True if the contract can be downloaded, false otherwise.
 * @example
 * const typeId1 = 'EXECUTIVE_SEARCH_123';
 * const typeId2 = 'STANDARD_SEARCH_456';
 *
 * console.log(canDownloadContract(typeId1)); // Output: true
 * console.log(canDownloadContract(typeId2)); // Output: false
 */
export function canDownloadContract(typeId) {
  if (!typeId) return false;

  return [...EXECUTIVE_SEARCH_IDS].includes(typeId);
}

/**
 * Parses and retrieves assignment status based on the provided parameters.
 *
 * @param {Object} params - The parameters for parsing status.
 * @param {string} params.status - The status parameter to parse.
 * @returns {number[]} An array representing assignment status.
 * @throws {Error} Throws an error if parsing fails.
 * @example
 * const params1 = { status: '["prospecting","in progress"]' };
 * const params2 = { status: '5' };
 *
 * console.log(parseStatus(params1)); // Output: [0, 1]
 * console.log(parseStatus(params2)); // Output: [1, 2, 3, 4, 5]
 */
export function parseStatus(params) {
  try {
    const assignmentStatus = !params.status
      ? [0, 1]
      : !params.status.includes("5")
      ? JSON.parse(params.status)
      : DEFAULT_FILTER_ASSIGNMENT_STATUS_OPTION.map((s) => s.value);

    return assignmentStatus;
  } catch (error) {
    // Return prospecting and in progress in case of error
    return [0, 1];
  }
}

/**
 * Retrieves the assignment type based on the provided type ID.
 *
 * @param { string|number} typeId - The type ID to determine the assignment type.
 * @returns {string} The assignment type as a string.
 * @example
 * const typeId1 = 'LEADERSHIP_ADVISORY_123';
 * const typeId2 = 'INTERIM_MANAGEMENT_456';
 * const typeId3 = 'EXECUTIVE_SEARCH_789';
 * const typeId4 = 'SOME_OTHER_TYPE';
 *
 * console.log(getAssignmentType(typeId1)); // Output: 'leadership_advisory'
 * console.log(getAssignmentType(typeId2)); // Output: 'interim_management'
 * console.log(getAssignmentType(typeId3)); // Output: 'executive_search'
 * console.log(getAssignmentType(typeId4)); // Output: 'unknown'
 */
export function getAssignmentType(typeId) {
  if (LEADERSHIP_ADVISORY_IDS.includes(typeId))
    return ASSIGNMENT_TYPES.LEADERSHIP_ADVISORY;
  if (INTERIM_MANAGEMENT_IDS.includes(typeId))
    return ASSIGNMENT_TYPES.INTERIM_MANAGEMENT;
  if (EXECUTIVE_SEARCH_IDS.includes(typeId))
    return ASSIGNMENT_TYPES.EXECUTIVE_SEARCH;

  return ASSIGNMENT_TYPES.UNKNOWN;
}

/**
 * Retrieves the descriptive status label based on the provided state.
 *
 * @param  {number} state - The state code to retrieve the status label.
 * @returns {string} The descriptive status label corresponding to the state.
 * @example
 * console.log(getAssignmentStatus(0)); // Output: "Prospecting"
 * console.log(getAssignmentStatus(1)); // Output: "In progress"
 * console.log(getAssignmentStatus(2)); // Output: "Closed"
 * console.log(getAssignmentStatus(3)); // Output: "Lost"
 * console.log(getAssignmentStatus(4)); // Output: "Interrupted"
 * console.log(getAssignmentStatus(5)); // Output: ""
 */
export function getAssignmentStatus(state) {
  const statusMap = {
    0: "Prospecting",
    1: "In progress",
    2: "Closed",
    3: "Lost",
    4: "Interrupted",
  };

  return statusMap[state] || "";
}

/**
 * Updates assignments in a data object based on the ATS person ID.
 *
 * @param {Object} params - The parameters for updating assignments.
 * @param {Object} params.data - The data object containing arrays to update.
 * @param {Object} params.replacementObject - The replacement object with new values.
 * @param {string} params.action - The action information.
 * @returns {Object} The updated data object with assignments modified.
 * @example
 * const data = {
 *   assignments1: [{ ats_person_id: '123', name: 'Assignment 1' }, { ats_person_id: '456', name: 'Assignment 2' }],
 *   assignments2: [{ ats_person_id: '789', name: 'Assignment 3' }]
 * };
 * const replacementObject = { status: 'Completed' };
 * const action = { ats_person_id: '456' };
 * const updatedData = updateAssignmentByAtsPersonId({ data, replacementObject, action });
 * console.log(updatedData);
 * // Output:
 * // {
 * //   assignments1: [{ ats_person_id: '123', name: 'Assignment 1' }, { ats_person_id: '456', name: 'Assignment 2', status: 'Completed' }],
 * //   assignments2: [{ ats_person_id: '789', name: 'Assignment 3' }]
 * // }
 */
export function updateAssignmentByAtsPersonId({
  data,
  replacementObject,
  action,
}) {
  _.forOwn(data, (array) => {
    const index = _.findIndex(array, { ats_person_id: action?.ats_person_id });

    if (index !== -1) {
      array[index] = { ...array[index], ...replacementObject };
    }
  });

  return data;
}

/**
 * Swaps or updates assignments in a data object based on the ATS person ID and action rules.
 *
 * @param {Object} params - The parameters for swapping or updating assignments.
 * @param {Object} params.data - The data object containing arrays of assignments.
 * @param {Object} params.replacementObject - The replacement object with new values.
 * @param {Object} params.action - The action information.
 * @returns {Object} The updated data object with assignments swapped or updated.
 * @example
 * const data = {
 *   assignments1: [{ ats_person_id: '123', name: 'Assignment 1' }, { ats_person_id: '456', name: 'Assignment 2' }],
 *   assignments2: [{ ats_person_id: '789', name: 'Assignment 3' }]
 * };
 * const replacementObject = { status: 'Completed' };
 * const action = { ats_person_id: '456', action_type: 'move' };
 * const updatedData = swapAssignmentByAtsPersonId({ data, replacementObject, action });
 * console.log(updatedData);
 * // Output:
 * // {
 * //   assignments1: [{ ats_person_id: '123', name: 'Assignment 1' }],
 * //   assignments2: [{ ats_person_id: '789', name: 'Assignment 3' }],
 * //   move: [{ ats_person_id: '456', name: 'Assignment 2', status: 'Completed', pipe: 'move' }]
 * // }
 */
export function swapAssignmentByAtsPersonId({
  data,
  replacementObject,
  action,
}) {
  const key = actionRules(action?.action_type);

  if (!(key?.length > 0)) return data;

  if (key === "current") {
    return updateAssignmentByAtsPersonId({ action, data, replacementObject });
  }

  let temp = {};
  _.forOwn(data, (array) => {
    const index = _.findIndex(array, {
      ats_person_id: action?.ats_person_id,
    });

    if (index !== -1) {
      temp = array.splice(index, 1);
      temp = {
        ...temp?.[0],
        ...replacementObject,
        ...(key !== "current" && { pipe: key }),
      };
    }
  });

  if (!(key in data)) {
    data[key] = [];
  }

  if (key !== "current" && key?.length > 0) {
    data[key].push(temp);
  }

  return data;
}

/**
 * Reverses the swap or updates assignments in a data object based on the ATS person ID and action rules.
 *
 * @param {Object} params - The parameters for reversing swap or updating assignments.
 * @param {Object} params.data - The data object containing arrays of assignments.
 * @param {Object} params.replacementObject - The replacement object with new values.
 * @param {Object} params.action - The action information.
 * @returns {Object} The updated data object with assignments reversed or updated.
 * @example
 * const data = {
 *   assignments1: [{ ats_person_id: '123', name: 'Assignment 1' }, { ats_person_id: '456', name: 'Assignment 2' }],
 *   assignments2: [{ ats_person_id: '789', name: 'Assignment 3' }]
 * };
 * const replacementObject = { status: 'Completed' };
 * const action = { ats_person_id: '456', action_type: ACTION.PHONE_CALL };
 * const updatedData = reverseSwapAssignmentByAtsPersonId({ data, replacementObject, action });
 * console.log(updatedData);
 * // Output:
 * // {
 * //   assignments1: [{ ats_person_id: '123', name: 'Assignment 1' }, { ats_person_id: '456', name: 'Assignment 2', status: 'Completed', pipe: KANBAN_COLUMN_STATES.IDENTIFIED }],
 * //   assignments2: [{ ats_person_id: '789', name: 'Assignment 3' }]
 * // }
 */
export function reverseSwapAssignmentByAtsPersonId({
  data,
  replacementObject,
  action,
}) {
  const key = actionRulesReverse(action?.action_type);

  if (!(key?.length > 0)) return data;

  let temp = {};
  _.forOwn(data, (array) => {
    const index = _.findIndex(array, {
      ats_person_id: action?.ats_person_id,
    });

    if (index !== -1) {
      temp = array.splice(index, 1);

      temp = {
        ...temp?.[0],
        ...replacementObject,
        ...(key !== "current" && { pipe: key }),
      };
    }
  });

  if (!(key in data)) {
    data[key] = [];
  }

  data[temp?.pipe].push(temp);

  return data;
}

/**
 * Generates an assignment off-limit configuration based on the provided parameters.
 *
 * @param {Object} params - The parameters for configuring off-limit assignments.
 * @param {string} params.perimeter - The type or area of the off-limit perimeter.
 * @param {boolean} params.offlimit - A boolean indicating whether the assignment is off-limit.
 * @param {number} params.month - The duration in months for which the assignment is off-limit.
 * @param {Array} params.businessUnit - The business unit for the assignment off-limit.
 * @returns {Object} An object representing the off-limit configuration:
 * - If `offlimit` is `true`, returns an object with `offlimit` set to `1`, `offlimit_type` set to `perimeter`, and `offlimit_duration` set to `month`.
 * - If `offlimit` is `false`, returns an object with `offlimit` set to `0`.
 *
 * @example
 * const config = getAssignmentOfflimit({ perimeter: 'Zone A', offlimit: true, month: 3 });
 * // Output: { offlimit: 1, offlimit_type: 'Zone A', offlimit_duration: 3 }
 *
 * const noOfflimitConfig = getAssignmentOfflimit({ perimeter: 'Zone A', offlimit: false, month: 3 });
 * // Output: { offlimit: 0 }
 */
export function generateOfflimitStatus({
  perimeter,
  offlimit,
  month,
  businessUnit,
}) {
  const isPartialPerimeter = perimeter === ASSIGNMENT_PERIMETER.partial;

  const businessUnitValue =
    businessUnit?.length > 0
      ? { offlimit_parts: businessUnit.map((unit) => unit.name) }
      : {};

  return offlimit
    ? {
        offlimit: 1,
        offlimit_type: perimeter,
        offlimit_duration: month,
        ...(isPartialPerimeter && businessUnitValue),
      }
    : {
        offlimit: 0,
      };
}

/**
 * Filters assignment data fields and default values based on the specified assignment type.
 *
 * @param {string} assignmentType - The current assignment type to filter out.
 * @param {Array<Object>} data - An array of assignment data objects, each containing `type`, `fields`, and `defaultValues`.
 * @param {string} data[].type - The type of the assignment.
 * @param {Array} data[].fields - An array of fields associated with the assignment type.
 * @param {Object} data[].defaultValues - An object containing default values for the fields.
 *
 * @returns {{fields: Array, defaultValues: Object}} An object containing:
 * - `fields`: An array of fields from assignment types that do not match the specified `assignmentType`.
 * - `defaultValues`: An object merging the default values of fields from assignment types that do not match the specified `assignmentType`.
 *
 * @example
 * const assignmentType = 'typeA';
 * const data = [
 *   { type: 'typeA', fields: ['field1', 'field2'], defaultValues: { field1: 'default1' } },
 *   { type: 'typeB', fields: ['field3'], defaultValues: { field3: 'default3' } }
 * ];
 *
 * const result = filteredAssignmentDataFields(assignmentType, data);
 * // result = {
 * //   fields: ['field3'],
 * //   defaultValues: { field3: 'default3' }
 * // }
 */
export function filteredAssignmentDataFields(assignmentType, data) {
  return data.reduce(
    (acc, { defaultValues, fields, type }) => {
      if (type !== assignmentType) {
        acc.fields.push(...fields);
        acc.defaultValues = {
          ...acc.defaultValues,
          ...defaultValues,
        };
      }

      return acc;
    },
    {
      fields: [],
      defaultValues: {},
    },
  );
}

/**
 * @typedef {Object} Producer
 * @property {Object[]} producer - The producer object array.
 * @property {number} net - The net billing percentage for the producer.
 */

/**
 * @typedef {Object} Originator
 * @property {Object[]} originator - The originator object array.
 * @property {number} gross - The gross billing percentage for the originator.
 */

/**
 * Formats billing information for producers and originators.
 * Calculates the net and gross values based on whether the producer and originator
 * belong to the same team (i.e., have the same manager).
 *
 * @param {Object} params - The parameters object.
 * @param {Array<Object>} [params.producers=[]] - An array of producer objects.
 * @param {Array<Object>} [params.originators=[]] - An array of originator objects.
 * @param {Array<Object>} [params.consultants=[]] - An array of all consultants object.
 *
 * @returns {{ producers: Producer[], originators: Originator[] }} - The formatted billing information.
 */
export function formatAssignmentProducerOriginatorBilling({
  producers = [],
  originators = [],
  consultants = [],
}) {
  if (
    producers.length === 1 &&
    originators.length === 1 &&
    consultants.length > 0
  ) {
    const [producer] = producers;
    const [originator] = originators;
    const producerManager = consultants.find(
      (consultant) => consultant?.id === producer.id,
    );

    const originatorManager = consultants.find(
      (consultant) => consultant?.id === originator.id,
    );

    const isInTheSameTeam =
      originatorManager?.manager?.id === producerManager?.manager?.id;

    const isProducerIdEqualToOriginatorId = producer.id === originator.id;

    const initialProducers = [
      {
        producer: [
          { ...producer, manager: { id: producerManager?.manager?.id } },
        ],
        net: isProducerIdEqualToOriginatorId
          ? PRODUCER_AND_ORIGINATOR_SAME_REPARTITION_VALUE
          : isInTheSameTeam
          ? IN_THE_SAME_TEAM_REPARTITION_VALUE
          : PRODUCER_NOT_IN_THE_SAME_TEAM_REPARTITION_VALUE,
      },
    ];

    if (!isProducerIdEqualToOriginatorId) {
      initialProducers.push({
        producer: [
          {
            ...originator,
            manager: { id: originatorManager?.manager?.id },
          },
        ],
        net: isInTheSameTeam
          ? IN_THE_SAME_TEAM_REPARTITION_VALUE
          : ORIGINATOR_NOT_IN_THE_SAME_TEAM_REPARTITION_VALUE,
      });
    }

    return {
      producers: initialProducers,
      originators: [
        {
          originator: [originator],
          gross: PRODUCER_AND_ORIGINATOR_SAME_REPARTITION_VALUE,
        },
      ],
    };
  }

  return {
    producers: producers.map((producer) => ({
      producer: [producer],
      net: "0",
    })),
    originators: originators.map((originator) => ({
      originator: [originator],
      gross: "0",
    })),
  };
}

/**
 * @typedef {Object} Assignment
 * @property {Object} [client] - The client object for the assignment.
 * @property {string} [client.name] - The client's name.
 * @property {Array<Object>} [producers] - The list of producers involved in the assignment.
 * @property {Array<Object>} [contributors] - The list of originators involved in the assignment.
 * @property {Object} [type_detail] - The assignment type details.
 * @property {string} [type_detail.name] - The name of the assignment type.
 * @property {number} [offlimit] - The offlimit status of the assignment (1 for Yes, 0 for No).
 * @property {Array<Object>} [researchers] - The list of researchers involved in the assignment.
 * @property {Array<Object>} [functions] - The functions involved in the assignment.
 * @property {string} [title] - The job title(s) for the assignment.
 * @property {number|string} [salary_max] - The maximum salary or budget for the assignment.
 * @property {string} [currency] - The currency of the budget or salary.
 * @property {string} [reference] - The reference ID for the assignment.
 */

/**
 * @typedef {Object} ContentItem
 * @property {string} title - The title of the content item.
 * @property {string} key - The key used to identify the content item.
 * @property {string|number} value - The value associated with the content item.
 */

/**
 * Formats the talent description content for an assignment by generating an array of content items.
 *
 * @param {Assignment} assignment - The assignment data containing client, producers, contributors, and other details.
 * @returns {ContentItem[]} - An array of content item objects, each containing title, key, and value fields.
 */
export function formatTalentDescriptionContentDetails(assignment) {
  return [
    createContentItem(
      "Client",
      "client",
      getValueOrDefault(assignment?.client?.name),
    ),
    createContentItem(
      "Producer(s)",
      "producers",
      extractFullName(assignment?.producers, "N/C"),
    ),
    createContentItem(
      "Originator(s)",
      "contributors",
      extractFullName(assignment?.contributors, "N/C"),
    ),
    createContentItem(
      "Assignment type",
      "assignment-type",
      getValueOrDefault(assignment?.type_detail?.name),
    ),
    createContentItem(
      "Offlimit",
      "offlimit",
      formatBooleanToString(assignment?.offlimit),
    ),
    createContentItem(
      "Researcher(s)",
      "researchers",
      extractFullName(assignment?.researchers, "N/C"),
    ),
    createContentItem(
      "Function(s)",
      "functions",
      extractCombinedName(assignment?.functions),
    ),
    createContentItem(
      "Job Title(s)",
      "job-title",
      getValueOrDefault(assignment?.title),
    ),
    createContentItem(
      "Max budget",
      "salary-max",
      getValueOrDefault(assignment?.salary_max),
    ),
    createContentItem(
      "Currency",
      "currency",
      getValueOrDefault(assignment?.currency),
    ),
    createContentItem(
      "Reference",
      "reference",
      getValueOrDefault(assignment?.reference),
    ),
  ];
}
